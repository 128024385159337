<template>
  <div class="message-component" v-show="visibleState">
    <div class="message-component-box">
      <Iconfont :icon="icon" style="font-size: 20px" />
      <span class="mc-box-txt">{{tooltip || "复制成功！"}}</span>
    </div>
  </div>
</template>
<script>
import Iconfont from "./Iconfont";
let timer = null;

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "iconsuccess"
    },
    tooltip: {
      type: String | Number,
      default: ""
    },
    time: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      visibleState: false
    }
  },
  watch: {
    visible: function (newValue, oldValue) {
      if (newValue) {
        this.visibleState = true;
        timer = setTimeout(() => {
          clearTimeout(timer);
          this.visibleState = false;
        }, this.time || 1000)
      }
    }
  },
  destroyed() {
    clearTimeout(timer);
  },
  components: {
    Iconfont
  }
};
</script>
<style lang="less" scoped>
.message-component {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}
.message-component-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: #fff;
}

.mc-box-txt {
  margin-left: 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
</style>
