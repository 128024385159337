<template>
  <div class="record-screen-component" :style="boxStyle">
    <span class="rs-component-title">时间范围</span>
    <a-range-picker :size="size" @change="dateChange" class="rs-component-date">
      <Iconfont icon="icondate" slot="suffixIcon" />
    </a-range-picker>
    <a-select
      class="rs-component-select"
      placeholder="请选择"
      :value="filterValue"
      @change="selectChange($event, 'select')"
    >
      <a-select-option
        v-for="item in filterArr"
        :value="item.value"
        :key="item.value"
        >{{ item.name }}</a-select-option
      >
    </a-select>
    <a-input-search
      class="rs-component-search"
      enter-button="搜索"
      placeholder="请输入搜索内容"
      @change="inputChange($event, 'input')"
      @search="pressChange($event, 'pressSeach')"
    >
      <a-icon type="search" slot="prefix" class="rsc-search-cion" />
    </a-input-search>
    <a-dropdown
      placement="bottomCenter"
      :visible="screenVisible"
      @visibleChange="screenChange"
    >
      <div class="rs-component-checked">
        <a-icon type="unordered-list" class="rsc-checked-list" />
        <a-icon type="down" class="rsc-checked-down" />
      </div>
      <div class="rsc-checked-dropdown" slot="overlay">
        <div class="rscc-dropdown-checklist">
          <a-checkbox
            key="all"
            :checked="checkedAll"
            :indeterminate="indeterminate"
            :disabled="!checkList.length"
            @change="checkedAllChange"
          >
            全选
          </a-checkbox>
        </div>
        <div
          class="rscc-dropdown-checklist"
          v-for="(item, index) in checkList"
          :key="item.title"
        >
          <a-checkbox
            :checked="item.checked"
            @change="checkedChange($event, index)"
          >
            {{ item.title }}
          </a-checkbox>
        </div>
      </div>
    </a-dropdown>
    <a-dropdown
      placement="bottomCenter"
      :visible="downloadVisible"
      @visibleChange="downloadChange"
    >
      <div class="rs-component-download">
        <a-icon type="download" class="rsc-download-download" />
        <a-icon type="down" class="rsc-download-down" />
      </div>
      <div class="rsc-download-dropdown" slot="overlay">
        <div class="rscd-dropdown-txt" @click="download('all')">
          导出全部数据
        </div>
      </div>
    </a-dropdown>
  </div>
</template>
<script>
import Iconfont from "./Iconfont";
export default {
  props: {
    boxStyle: {
      type: String,
      default: ""
    },
    dateChange: {
      type: Function,
      default: function () {}
    },
    selectChange: {
      type: Function,
      default: function () {}
    },
    inputChange: {
      type: Function,
      default: function () {}
    },
    pressChange: {
      type: Function,
      default: function () {}
    },
    download: {
      type: Function,
      default: function () {}
    },
    filterArr: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    filterValue: {
      type: String | Number,
      default: ""
    },
    downloadType: {
      type: String | Number,
      default: false
    }
  },
  data() {
    return {
      size: "default",
      checkedAll: !!this.columns.length,
      indeterminate: false,
      checkList: this.columns.filter(item => item.abandon !== true),
      screenVisible: false,
      downloadVisible: false
    };
  },
  mounted() {
    this.checkList.forEach(item => {
      item.checked = true;
    });
  },
  components: {
    Iconfont
  },
  methods: {
    // 筛选dropdown
    screenChange(value) {
      this.screenVisible = value;
    },
    // 下载dropdown
    downloadChange(value) {
      this.downloadVisible = value;
    },
    checkedAllChange(e) {
      let arr = [];
      this.checkList.forEach(item => {
        item.checked = e.target.checked;
      });
      this.indeterminate = false;
      this.checkedAll = e.target.checked;
      if (e.target.checked) {
        this.checkList.forEach(item => {
          let { checked, ...other } = item;
          arr.push(other);
        });
      }
      this.columns.forEach(item => {
        if (item.abandon) {
          arr.push(item);
        }
      });
      this.$emit("checkedChange", arr);
    },
    checkedChange(e, i) {
      let arr = [];
      this.checkList[i].checked = e.target.checked;
      let aIndex = this.checkList.findIndex(item => item.checked === false);
      let hIndex = this.checkList.findIndex(item => item.checked === true);
      this.checkedAll = aIndex === -1 ? true : false;
      this.indeterminate =
        aIndex === -1 ? false : aIndex !== -1 && hIndex === -1 ? false : true;
      this.$forceUpdate();
      this.checkList.forEach(item => {
        let { checked, ...other } = item;
        if (item.checked) {
          arr.push(other);
        }
      });
      this.columns.forEach(item => {
        if (item.abandon) {
          arr.push(item);
        }
      });
      this.$emit("checkedChange", arr);
    }
  }
};
</script>
<style lang="less">
@import "./../style/recordScreen.less";
</style>
