<template>
  <div class="pagination-component">
    <a-pagination
      :current="current"
      :pageSize="pageSize"
      :pageSizeOptions="pageSizeOptions"
      :showQuickJumper="showQuickJumper"
      :showSizeChanger="showSizeChanger"
      :total="total"
      :showTotal="showTotal"
      @change="change"
      @showSizeChange="showSizeChange"
    ></a-pagination>
  </div>
</template>
<script>
export default {
  props: {
    current: {
      type: String | Number,
      default: 1
    },
    pageSize: {
      type: String | Number,
      default: 10
    },
    pageSizeOptions: {
      type: Array,
      default: () => ["10", "20", "30", "40"]
    },
    showQuickJumper: {
      type: Boolean,
      default: true
    },
    showSizeChanger: {
      type: Boolean,
      default: true
    },
    total: {
      type: String | Number,
      default: 0
    },
    showTotal: {
      type: Function,
      default: total => {
        return `共有 ${total} 条`;
      }
    },
    change: {
      type: Function,
      default: () => {}
    },
    showSizeChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="less">
@import url("./../../style/paginationComponent.less");
</style>
